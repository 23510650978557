import React from "react"
import image1 from "../images/biglogo.png"
import "./about.css"
import "./styles.scss"
import "animate.css"

export default function About() {
  return (
    <div id="home" className="about-home">
      <div style={{ display: "contents" }}>
        <section className="about-section-1">
          <div className="about-header">
            How The Beytna Foundation Came To Be
          </div>
        </section>
        <section className="about-section-2">
          <div className="about-headline">
            <div style={{ display: "contents" }}>
              <p>
                For many years, my family has been sending money to Lebanon to
                help those in need. Many of my relatives still reside in the
                town of Sawfar, and they would always tell my family and I about
                local families in desperate need of financial assistance. My
                mother-in-law would tell my husband, who is a pediatrician,
                about their lack of medicine and medical consultations. Families
                and local establishments, like the nearby orphanage, would come
                to her for help and she always met their needs as best as she
                could. Each year, we would collect a sum of money from friends
                and family members to send to Lebanon so that she could give it
                directly to those in need.
              </p>
              <p>
                The United Nations now estimates that 82% of people in Lebanon
                live below the poverty line, and 36% of the population is living
                in extreme poverty.
              </p>
              <p>
                I knew that in order to create a lasting effect in the
                communities of Lebanon, I would need to raise awareness about
                the living conditions there and would also need help in raising
                funds to provide them with necessary services and utilities. I
                wanted to have a direct impact on a place very close to my
                heart- the mountain region of Lebanon, where my husband and I
                both grew up. It became clear that this was my calling, and my
                way of continuing the magnanimous work of my mother-in-law. It
                is also a way of giving my children a stronger connection to the
                country from which we came, because no matter where we are, we
                will always have a place to call our home, our <i>beytna</i>.
              </p>
              <p>
                With thanks and warm regards,
                <br />
                Nassab Ahmadie
              </p>
            </div>
          </div>
        </section>
        <section className="about-section-4">
          <div className="about-image-wrap">
            <div className="about-image">
              <picture>
                <img src={image1} className="about-img" alt="large logo" />
              </picture>
            </div>
          </div>
        </section>
        <section className="about-section-3">
          <div className="about-text-left">
            <div className="about-text">MESSAGE FROM FOUNDER</div>
          </div>
          <div className="about-text-right">
            <div className="about-text-headline">Meaning Behind The Look</div>
            <div className="about-text-body">
              <div style={{ display: "contents" }}>
                <p>
                  The Beytna Foundation brings Lebanon and the United States of
                  America together; our logo is designed to harmoniously display
                  how we do this. The home is framed by golden walls that extend
                  beyond the words to represent the long distances travelled.
                  Gold represents strength, wealth, and generosity. The door to
                  the home is red to incorporate the old (and often forgotten)
                  American tradition of families painting their front doors red
                  to let travelers know that the home was a welcoming place to
                  rest, replenish, and receive help. The green of our logo
                  represents the color of Lebanon's famous and beloved cedar
                  trees, which are a major symbol of national identity and
                  pride.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="about-section-3">
          <div className="about-text-left">
            <div className="about-text">OUR TEAM</div>
          </div>
          <div className="about-text-right">
            <div className="about-text-body">
              <div style={{ display: "contents" }}>
                <p style={{ marginTop: "0px" }}>
                  President - <strong>Nassab Ahmadie</strong>
                  <br />
                  Secretary - <strong>Sandy Ha</strong>
                  <br />
                  Treasurer - <strong>Rawand AbdelSalam</strong>
                  <br />
                  {/* Honorary Board Advisor{" "}-{" "}<strong>Ziad Ahmadie</strong><br/> */}
                  Software Engineer - <strong>Sam Chakra</strong>
                  <br />
                  Events - <strong>Najat Aboukamar</strong>
                  <br />
                  Social Media Director - <strong>Megan Chakra</strong>
                  <br />
                  Communications Editor - <strong>Gabriella Williams</strong>
                  <br />
                  Logistics Coordinator (Lebanon) -{" "}
                  <strong>Ceasar Ahmadieh</strong>
                  <br />
                  Beneficiary Coordinator (Lebanon) -{" "}
                  <strong>Jomana Ahmadieh</strong>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </section>
        <footer className="footer">
          <div className="main">
            <div className="interest">
              <div className="headline">
                <div className="parent">
                  <div className="text animate__animated animate__slideInUp">
                    Ready to help?
                  </div>
                </div>
              </div>
              <a
                href="https://www.paypal.com/donate/?hosted_button_id=UHGSRNCC822XC"
                target="_blank"
                rel="noreferrer"
                className="content-head"
              >
                <div className="content">
                  <div className="label">
                    <div className="index-rolling-text">Donate</div>
                  </div>
                  <div class="wrapper">
                    <a
                      href="https://www.paypal.com/donate/?hosted_button_id=UHGSRNCC822XC"
                      target="_blank"
                      rel="noreferrer"
                      class="chevron-d"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="55"
                        height="55"
                        viewBox="0 0 55 55"
                      >
                        <path
                          class="circle"
                          d="M27.5 2C41.6 2 53 13.4 53 27.5S41.6 53 27.5 53 2 41.6 2 27.5 13.4 2 27.5 2M27.5 0C12.3 0 0 12.3 0 27.5S12.3 55 27.5 55 55 42.7 55 27.5 42.7 0 27.5 0L27.5 0z"
                        />
                        <polygon
                          class="arrow"
                          points="34.9 31 28.5 35 28.5 17 26.5 17 26.5 35 20.1 31 19 32.7 27.5 38 36 32.7 "
                        />
                        <polygon
                          class="arrow-2"
                          points="34.9 31 28.5 35 28.5 17 26.5 17 26.5 35 20.1 31 19 32.7 27.5 38 36 32.7 "
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </a>
            </div>
            <div className="top">
              <div className="logo-box">
                {/* <div className="logo">Site Under Construction</div> */}
              </div>
              <div className="info-container">
                <div className="list-container">
                  <ul style={{ padding: "0", listStyle: "none" }}>
                    {/* <li className="address">
                        Address Here
                        <br />
                        Here
                        <br />
                        Here
                      </li> */}
                    {/* <li className="phone">+34 93 350 55 08</li> */}
                    <li className="email-container">
                      <a className="email" href="mailto:info@beytna.foundation">
                        info@beytna.foundation
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="list-container-2">
                  <ul style={{ padding: "0", listStyle: "none" }}>
                    {/* <li className="phone">phone number</li> */}
                    <li className="email-container">
                      <a className="email" href="mailto:info@beytna.foundation">
                        info@beytna.foundation
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="list-container-3">
                  <ul style={{ padding: "0", listStyle: "none" }}>
                    <li className="legal">
                      <a className="item" href="/#">
                        Legal disclaimer
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="bottom">
              <div className="item">
                ©2022 Beytna Charitable Foundation. All rights reserved.
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}
